<template>
  <!-- Content Header (Page header) -->
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-8">
          <h1 class="m-0">MVP</h1>
        </div><!-- /.col -->
        <div class="col-sm-4">
          <div id="entry-date-range" style="background: #fff; cursor: pointer; padding: 5px 10px; border: 1px solid #ccc; width: 100%">
            <i class="fa fa-calendar"></i>&nbsp;
            <span></span> <i class="fa fa-caret-down"></i>
          </div>
        </div>
      </div><!-- /.row -->
    </div><!-- /.container-fluid -->
  </div>
  <!-- /.content-header -->

  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4"
             v-for="mvp in Object.keys(mvps)"
             :key="'mvp_'+mvp">
          <div class="card">
            <div class="card-header bg-primary text-capitalize">{{ mvps[mvp].title }}</div>
            <div class="card-body">
              <table class="table table-bordered">
                <tbody>
                <tr v-if="!mvps[mvp].users.length && !mvps[mvp].isLoading">
                  <td colspan="2">No data</td>
                </tr>

                <tr v-if="mvps[mvp].isLoading">
                  <td colspan="2" class="text-center">
                    <div class="spinner-border" role="status">
                    </div>
                  </td>
                </tr>

                <template v-else>
                  <tr v-for="(user, i) in mvps[mvp].users" :key="'mvp_user_'+i">
                    <td>{{ user.name }}</td>
                    <td>
                      <span v-if="mvp === 'power'">{{ user.score.toFixed(2) }}</span>
                      <span v-if="mvp === 'wow'">{{ user.score.toFixed(2) }}%</span>
                      <span v-if="mvp === 'hourly_monty'">${{ user.score.toFixed(2) }}</span>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  data() {
    return {
      mvps: {
        power: {
          title: "Power",
          isLoading: false,
          users: []
        },
        wow: {
          title: "Wow",
          isLoading: false,
          users: []
        },
        hourly_monty: {
          title: "Hourly Monty",
          isLoading: false,
          users: []
        }
      },
      filters: {
        date: ''
      },
    }
  },
  mounted() {
    let start = window.moment().startOf('week');
    let end = window.moment();
    let self = this;

    window.$('#entry-date-range').daterangepicker(
        {
          autoUpdateInput: false,
          startDate: start,
          endDate: end,
          locale: {
            cancelLabel: 'Clear',
            format: 'MMM D, YYYY'
          },
          ranges: {
            'Today': [window.moment(), window.moment()],
            'This Week': [window.moment().startOf('week'), window.moment()],
            'Last Week': [window.moment().subtract(7, 'days').startOf('week'), window.moment().subtract(7, 'days').endOf('week')],
            'This Quarter': [window.moment().quarter(window.moment().quarter()).startOf('quarter'), window.moment()],
            'Last Quarter': [window.moment().quarter(window.moment().quarter() - 1).startOf('quarter'), window.moment().quarter(window.moment().quarter() - 1).endOf('quarter')],
            'This Year': [window.moment().startOf('year'), window.moment()],
            'Last Year': [window.moment().subtract(1, 'years').startOf('year'), window.moment().subtract(1, 'years').endOf('year')],
            // 'This Month': [window.moment().startOf('month'), window.moment().endOf('month')],
            // 'Last Month': [window.moment().subtract(1, 'month').startOf('month'), window.moment().subtract(1, 'month').endOf('month')],
            // 'Year to Last Week': [self.getFirstWeekDay(window.moment().startOf('year'), 0), window.moment().subtract(1, 'weeks').endOf('week')],
          }
        },
        function (start, end) {
          self.changeDateRange(start, end);
        }
    )

    this.changeDateRange(start, end);
  },
  methods: {
    changeDateRange(start, end) {
      window.$('#entry-date-range span').html(start.format('MMM D, YYYY') + ' - ' + end.format('MMM D, YYYY'));
      this.filters.date = start.format('YYYY-MM-DD') + '/' + end.format('YYYY-MM-DD')

      this.loadData();
    },
    loadData() {
      const mvps = ['hourly_monty', 'wow', 'power'];

      mvps.forEach(mvp => {
        this.mvps[mvp].isLoading = true;

        this.axios.get('/mvp', {
          params: {...this.filters, type: mvp}
        }).then((response) => {
          this.mvps[mvp].users = response.data.data;
        }).finally(() => {
          this.mvps[mvp].isLoading = false;
        })
      });
    }
  }
}
</script>